import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { checkIfHomePage } from 'src/utils/checkIfHomePage';

export const useNavConfigs = (isScrollable, isHeaderHovered) => {
  const isHomePage = checkIfHomePage();

  const {
    widgetContextState: { isBuilder, activeHeaderData },
  } = useWidgetDndContextData();

  const globalClass = activeHeaderData?.headerPropsConfig?.globalClass || {};
  const { staticClass, scrollClass } = globalClass || {};

  const isTransparent =
    globalClass?.staticClass?.base_config?.defaultFillColorType === 'transparent';

  const shouldUseScrollClass =
    isTransparent && (isScrollable || isHeaderHovered || (!isBuilder && !isHomePage));
  const showTransparentBgHeader = isTransparent && !shouldUseScrollClass;
  const currentClass = shouldUseScrollClass ? scrollClass : staticClass;

  return {
    isHomePage,
    isBuilder,
    globalClass,
    scrollClass,
    staticClass,
    currentClass,
    isTransparent,
    shouldUseScrollClass,
    showTransparentBgHeader,
  };
};
